'use client'
import Slider from "react-slick";
import { useState, useEffect } from 'react';
import styles from './EventsAccordion.module.css';
import { EventsCard } from './EventsCardConstants';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';


const EventsAccordion = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        if (isOpen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1253,
                settings: {
                    variableWidth: true,
                    infinite: true,
                    autoplay:true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    infinite: true,
                    autoplay:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={`${styles.accordionWrapper}`}>
            {isOpen && <div className={styles.backdrop} onClick={toggleAccordion}></div>}
            <div className={`${styles.accordion} ${isOpen ? styles.open : ''}`}>
                <div className={styles.header} onClick={toggleAccordion}>
                    <span className={styles.headertxt}>{`Skill Up This Winter – Accelerate Your Career in 6 Weeks!`}</span>
                    <span className={styles.toggleIcon}>{isOpen ? <FaChevronDown /> : <FaChevronUp />}</span>
                </div>
                <div
                    className={styles.content}
                >
                    <div className={`${styles.cardContainer} events-card-container`}>
                        <Slider {...settings}>

                            {
                                EventsCard.map((event) => (
                                    <a key={event.id} className={`${styles[`card_${event.id}`]}`} href={event.link} target='_blank'>
                                        <div>
                                            <h3>{event.head}</h3>
                                            <ul>
                                                {
                                                    event.para.map((para, idx) => (
                                                        <li key={idx}>
                                                            <div>
                                                                <img src={event.tickImg} alt='tick-img' />
                                                            </div>
                                                            <p>{para}</p>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </a>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventsAccordion;
