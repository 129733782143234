import TickImg from '../../resources/images/events-card-tick.svg'

const EventsCard=[
    {
        id:1,
        head:"Java SpringBoot Backend",
        tickImg:TickImg,
        para:["2 Minor + 1 Capstone Project","1 on 1 Mock Interview","Career Asisstance & Resume Building","Recorded + Weekend Live Sessions"],
        link:"https://events.programmingpathshala.com/Events/103"
    },
    {
        id:2,
        head:"MERN Full Stack",
        tickImg:TickImg,
        para:["3 Minor + 2 Major + 1 Capstone Project","Rigorous 20 Hrs/Week Efforts","Career Asisstance & Resume Building","Recorded + Weekend Live Sessions"],
        link:"https://events.programmingpathshala.com/Events/104"
    },
    {
        id:3,
        head:"Data Analytics",
        tickImg:TickImg,
        para:["Master SQL, PySpark & Databricks","3+ Real-World Case Studies","Advanced Query Optimization","Build Live Dashboard"],
        link:"https://events.programmingpathshala.com/Events/105"
    },
    {
        id:4,
        head:"Data Structure & Algorithm",
        tickImg:TickImg,
        para:["Master 150 Top Interview Questions","1 on 1 Mock Interview","Career Asisstance & Resume Building","Recorded + Weekend Live Sessions"],
        link:"https://events.programmingpathshala.com/Events/102"
    }
]

export {EventsCard};